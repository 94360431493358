* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #121212;
}

main {
  width: 100vw;
  /* border: 2px solid rgb(155, 155, 155); */
  max-width: 1250px;
  margin: 0 auto;
  /* background: linear-gradient(140deg, #0c0c0c, #191883, #1350c7); */
  /* background: linear-gradient(200deg, #0c0c0c, #191883, #1350c7); */
  /* background: linear-gradient(144deg, #00ffbd, #00a5ff); */
  /* background: linear-gradient(144deg, #539bec, #001220); */
  /* background-size: 600% 600%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    -o-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite; */
  background: #121212;
  /* overflow: hidden; */
  /* border: 2px solid red; */
  height: 100lvh;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 57%;
  }
  50% {
    background-position: 100% 44%;
  }
  100% {
    background-position: 0% 57%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 57%;
  }
  50% {
    background-position: 100% 44%;
  }
  100% {
    background-position: 0% 57%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 0% 57%;
  }
  50% {
    background-position: 100% 44%;
  }
  100% {
    background-position: 0% 57%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 57%;
  }
  50% {
    background-position: 100% 44%;
  }
  100% {
    background-position: 0% 57%;
  }
}

.app-container {
  /* position: relative; */
  margin: 0 auto;
  padding: 0;
  width: 100%;
  /* max-width: 1250px; */
}

.app-container .auth-btn {
  /* border: 2px solid red; */
  /* margin-left: auto; */
  margin: 0 10px;
  position: absolute;
  top: 25%;
  right: 0.5%;
}

.app-container .auth-btn button {
  outline: none;
  border: none;
  padding: 6px 15px;
  border-radius: 10px;
  color: white;
  font-weight: 800;
  background-color: black;
  cursor: pointer;
}

.app-container .logo-header {
  width: 100dvw;
  max-width: 1248px;
  height: 6lvh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  /* border-bottom: 2px solid black; */
  z-index: 100;
  /* background-color: white; */
  /* margin-bottom: 0.5rem; */
  /* margin-bottom: -10px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
  background-color: #1f1f1f;
}

.app-container .logo-header .logo-container {
  /* border: 2px solid blue; */
  /* margin:0 auto; */
  /* margin-left: 50px; */
}

.app-container .logo-container .Logo {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: rgb(244, 244, 244);
}

.app-container .logo-container .logo-title {
  margin-left: 12px;
}

.app-container nav {
  border-top: 2px solid black;
  height: 7dvh;
  width: 100vw;
  max-width: 1248px;
  position: fixed;
  bottom: 0;
  background: #f2f2f2;
  background-color: #1f1f1f;
  /* background: blueviolet; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
}
