.intro-container {
  height: 100vh;
  /* border:30px solid black; */
  display: flex;
  background-color: aliceblue;
  flex-direction: column;
  font-weight: 700;
  justify-content: space-evenly;
  align-items: center;
  padding: 2.5rem;
  font-family: "Inter", sans-serif;
}

.intro-container .brand img {
  display: block;
  margin: 0 auto;
}

.intro-container .intro-slider {
  width: 100%;
  height: auto;
  /* border: 2px solid black; */
}

.intro-container .intro-slider .slides img {
  border-radius: 35px;
  display: block;
  margin: 0 auto;
  height: 15rem;
  width: 15rem;
}

.intro-container .intro-slider .slides {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-container .intro-slider .slides * {
  margin: 1.5rem 0;
}

.intro-container .intro-slider .slides .content {
  /* border: 2px solid red; */
  margin: 0 auto;
  text-align: center;
  width: 66vw;
}

.intro-container .intro-slider .slides .content h5 {
  font-size: 23px;
  font-weight: 700;
}

/* Customize the container of the dots */
.intro-container .slick-dots {
  bottom: 20px;
  /* Adjust the position */
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  /* border: 2px solid red; */
}

.intro-container .slick-dots.custom-top-intro {
  top: 394px !important;
}

/* Customize each dot */
.intro-container .slick-dots li button:before {
  font-size: 12px;
  /* Adjust the size */
  color: gray;
  /* Default color */
  opacity: 1;
  /* Ensure the dot is fully opaque */
}

/* Customize the active dot */
.intro-container .slick-dots li.slick-active button:before {
  color: black;
  /* Color for the active dot */
  opacity: 1;
  /* Ensure the active dot is fully opaque */
}

/* Optionally, change the background or shape of the dots */
.intro-container .slick-dots li button {
  background: none;
  /* Remove any background */
  border: none;
  /* Remove any border */
}

/* Customize the spacing between dots */
.intro-container .slick-dots li {
  margin: 0 5px;
  /* Adjust the spacing between dots */
}

/* Example of changing dot shape to a circle */
.intro-container .slick-dots li button:before {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: "";
  display: inline-block;
  background: gray;
}

/* Example of changing active dot shape to a circle */
.intro-container .slick-dots li.slick-active button:before {
  background: black;
  /* Color for the active dot */
}

.intro-container .button-container {
  /* border: 2px solid black; */
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.intro-container .button-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  font-family: "Inter", sans-serif;
  border-radius: 5px;
  background-color: #000000;
  color: white;
  font-weight: 900;
  transition: background-color 0.3s ease;
  width: 15rem;
  max-width: 500px;
}

.intro-container .button-container button:hover {
  background-color: #0056b3;
}
