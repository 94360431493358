/* skeletonCard.css */
.skeleton-card {
  background-color: #262626;
  border-radius: 8px;
  margin: 10px;
  width: 152px;
  animation: shimmer 70s infinite linear;
  /* border: 5px solid red; */
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 33s infinite linear;
}
.skeleton-image {
  height: 150px;
  background: #6b6b6b;
  margin-bottom: 10px;
  width: 100%;
}
.skeleton-text {
  height: 20px;
  background: #6b6b6b;
  margin: 5px 0;
  margin: 0 auto;
  width: 80%;
}
.skeleton-title {
  width: 60%;
  margin: 0 0.7rem;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #6b6b6b;
}
.skeleton-description {
  width: 60%;
  height: 10px;
  margin: 0 1rem;
  background-color: #6b6b6b;
  border-radius: 5px;
}
.skeleton-avatar-stack {
  height: 30px;
  margin: 0 1rem;
  width: 50%;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #6b6b6b;
}
.skeleton-button {
  height: 30px;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 12px;
  border-radius: 30px;
  background-color: #6b6b6b;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
