.chat-header-container {
  /* border: 2px solid red; */
  background-color: #ffffff !important;
  height: 12lvh;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 2px solid #0000000d;
}

.chat-header-container .group-chat-info {
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
}

.chat-header-container .group-chat-info .link-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.chat-header-container .group-chat-info .image-container {
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  margin: 0 0.6rem;
}

.chat-header-container .image-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-header-container .group-info-text .group-name {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.chat-header-container .group-info-text .group-user-count {
  display: block;
  font-size: 0.9rem;
  color: #00000080;
}

.chat-header-container .group-members-icon-container {
  /* border: 2px solid red; */
  margin-left: auto;
}
