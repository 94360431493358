.home-container {
  box-sizing: border-box;
  margin: 0;
  height: auto;
  /* background: #ffffff; */
  padding: 0 0.4rem 0.4rem 0.4rem;
  /* border: 2px solid blue; */
  max-width: 100svw !important;
  color: rgb(229, 229, 229);
}

.home-container .home-title {
  font-size: 1.5em;
  margin: 0.5rem 0;
  text-align: center;
  font-weight: 900;
}

.home-container .header {
  /* border: 2px solid black; */
  display: inline;
  width: auto;
  /* text-align: center; */
  font-size: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.7rem;
}

.home-container .header > div {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container .header select.city-select {
  font-size: 16px;
  background-color: #ffffff;
  border: none;
  outline: none;
  appearance: none;
  /* color: #333; */
  color: white;
}

.home-container .header select.city-select:focus {
  outline: none;
}

.home-container .dropdown {
  position: relative;
  font-size: 17px;
  font-weight: 600;
}

.home-container .dropdown-header {
  display: flex;
  align-items: center;
  /* background-color: #f1f1f1; */
  border: none;
  padding: 5px;
  cursor: pointer;
}

.home-container .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  margin-top: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-container .dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.home-container .dropdown-item:hover {
  background-color: #f1f1f1;
}

.home-container .events-container {
  /* border: 2px solid black; */
}

.home-container .category {
  /* border: 2px solid red; */
  margin-bottom: 1rem;
  padding: 5px;
}

.home-container .category:last-of-type {
  /* border: 2px solid red; */
  margin-bottom: 4rem;
}

.home-container .category .category-txt {
  display: block;
  margin-bottom: 1.2rem;
}

.home-container .events-container .card {
  min-height: 306px;
  /* width: 212px; */
  width: 152px;
  /* margin: 20px 50px; */
  /* border: 2px solid white; */
  background-color: #1f1f1f;
  border-radius: 20px;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  height: auto;
}

.home-container .mySwiper {
  /* border: 2px solid red; */
  min-height: 376px;
  height: auto;
  /* overflow-x: visible; */
  /* overflow: visible; */
}

.home-container .mySwiper .swiper-pagination {
  /* border: 2px solid red; */
  /* top:95%; */
  bottom: 0px;
}

.home-container .mySwiper .swiper-pagination span {
  height: 10px;
  width: 10px;
  background-color: white;
}

.home-container .card img {
  /* border: 2px solid red; */
  width: 100%;
  height: 10rem;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
  display: block;
}

.home-container .card .event-title {
  font-size: 12px;
  word-break: break-word;
  text-align: center;
  margin: 0 0.7rem;
  display: grid;
  place-items: center;

  /* border: 2px solid green; */
  min-height: 40px;
  /* max-height: 40px; */
}

.home-container .events-card-text {
  word-break: break-all;
  word-break: break-word;
  margin: 0;
  margin: 0 1rem;
  display: grid;
  place-items: center;
  /* text-align: left; */
  /* border: 2px solid red; */
  min-height: 45px;
  color: #ececec;
  text-align: center;
  font-size: 11px;
}

.home-container .card .read-more-link {
  margin-left: 0.3rem;
  font-weight: bolder;
  font-size: 12px;
  cursor: pointer;
  /* letter-spacing: -1px; */
  text-decoration: underline;
}

.home-container .card .user-img-stack-container {
  position: relative;
  /* border: 2px solid red; */
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home-container .user-img-stack-container img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 4px;
  margin-left: -6px;
  z-index: 1;
  border: 1px solid white;
}

.home-container .user-img-stack-container img:first-child {
  margin: 0;
}

.home-container .user-img-stack-container .more-info {
  border: 2px solid white;
  height: 30px;
  width: 30px;
  margin: 0 4px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 10px;
}

.home-container .more-info span {
  margin-left: -2px;
}

.home-container .button-container button {
  /* border: 2px solid green; */
  /* background-color: black; */
  outline: none;
  border: none;
  background-color: white;
  /* background-color: black; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  display: flex;
  padding: 5px 15px;
  /* flex-direction: row;
  justify-content: center;
  align-items: center; */
  margin: 0 auto;
  /* margin-top: 5px; */
  margin-bottom: 12px;
  /* color: white; */
  color: black;
  font-weight: 700;
}
