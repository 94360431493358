.admin-panel-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-panel-container .miscellanous-button-container {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Darker overlay for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Container */
.modal {
  position: relative;
  width: 90%;
  max-width: 600px; /* Max width for larger screens */
  background: #fff;
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  outline: none;
}

/* Modal Header */
.modal h2 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #333; /* Dark text for better readability */
}

/* Modal Content */
.modal input[type="text"],
.modal textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd; /* Light border for inputs */
  border-radius: 4px;
  box-sizing: border-box;
}

/* Modal Buttons */
.modal button {
  background-color: #007bff; /* Blue background for buttons */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.modal button.close {
  background-color: #6c757d; /* Grey background for close button */
}

.modal button.close:hover {
  background-color: #5a6268; /* Darker grey on hover */
}

/* Modal Input Styling */
.modal input[type="text"],
.modal textarea {
  font-size: 1rem;
}

.modal input[type="text"]::placeholder,
.modal textarea::placeholder {
  color: #999; /* Placeholder color */
}

.modal textarea {
  height: 100px; /* Fixed height for textarea */
  resize: vertical; /* Allow vertical resizing */
}

.admin-panel-container .miscellanous-button-container > div {
  margin: 0 1rem;
}

.admin-panel-container .miscellanous-button-container button {
  width: 3rem;
  padding: 5px;
  margin: 0 0.5rem;
}

.admin-panel-container .event-form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.admin-panel-container .event-form-container h2 {
  margin-bottom: 20px;
}

.admin-panel-container .event-form-container form {
  display: flex;
  flex-direction: column;
}

.admin-panel-container .event-form-container input,
.admin-panel-container .event-form-container textarea,
.admin-panel-container .event-form-container select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-panel-container .event-form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-panel-container .events-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
}

.admin-panel-container .events-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.admin-panel-container .event-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  /* border: 2px solid red; */
  width: 25%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px); /* Adjust width to fit three items per row */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.admin-panel-container .event-card img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.admin-panel-container .event-card h3 {
  margin-bottom: 10px;
}

.admin-panel-container .event-card p {
  margin-bottom: 10px;
}

.admin-panel-container .event-card-actions {
  display: flex;
  gap: 10px;
}

.admin-panel-container .event-card-actions button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-panel-container .categories-container {
  /* border: 2px solid red; */
}

.admin-panel-container .categories-container .category-card {
  border: 2px solid black;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.admin-panel-container .category-card button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 7px;
  outline: none;
  border: none;
  cursor: pointer;
}
