.chat-view-container {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  height: 88lvh;
  background-color: white;
  /* border: 2px solid red; */
}

.chat-view-container .pinned-messages-container {
  padding: 1px 0;
  width: 100%;
  overflow: visible;
  /* border: 2px solid black; */
  min-height: 4lvh;
  /* margin-bottom: 10px; */
  background: #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(223, 223, 223); */
  text-align: center;
}

.chat-view-container .pinned-messages-container .empty-pin-msg-text {
  margin-bottom: 0.2rem;
}

.pinned-messages-container .dropdown-icon {
  float: right;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  flex-direction: column;
}

.dropdown-content.show {
  display: flex;
}

.dropdown-item {
  margin-top: 10px;
}

/* .chat-view-container .pinned-messages-container .pinned-message{ */
/* border: 2px solid red; */
/* margin-bottom: 10px; */
/* width: 100%; */
/* } */

/* .chat-view-container .pinned-message .pinned-content{ */
/* border: 2px solid red; */
/* display: flex;
  align-items: center;
  padding: 3px 9px;
  border-radius: 5px;
  background-color: #ccc;
  width: 100%;
  margin-left: 20px; */
/* } */

.chat-view-container .messages-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 88%;
  /* border: 2px solid green; */
  /* position: relative; */
}

.chat-view-container .loader {
  height: 100%;
  display: grid;
  place-items: center;
  /* border: 12px solid black; */
}

.chat-view-container .reply-container {
  padding: 1rem;
  border-top: 1px solid #ccc;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
}

.chat-view-container form {
  padding: 1rem;
  border-top: 1px solid #ccc;
}
